import React, { FC, ReactElement, useLayoutEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

export const Footer: FC = (): ReactElement => {
  const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [windowWidth, windowHeight] = useWindowSize();
  // console.log(windowHeight);
  return (
    <Box
      sx={{
        width: "100%",
        height: 20,
        backgroundColor: "#1976d2",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        position: "absolute",
        // bottom: "0",
        top: windowHeight - 52,
        // appbarheight+thisboxheight
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {`© ${new Date().getFullYear()} naoto-kagaya.com`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
