import { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
// import Markdown from "./../contents/Test/index.md";

import { Box, Card, Grid, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock";
import MdImage from "./MdImage";

type props = { id?: string | null };

const EachPage: FC<props> = (props) => {
  const { id } = props.id ? { ...props } : useParams();

  const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [windowWidth, windowHeight] = useWindowSize();

  // console.log("EachPage");
  // console.log(`./../contents/${id}/index.md`);
  const [markdown, setMarkdown] = useState("");
  const [title, setTitle] = useState();
  const [date, setDate] = useState();
  const [url, setUrl] = useState("");

  const memoForMd = useMemo(() => {
    return (
      <ReactMarkdown
        components={{
          code: CodeBlock,
          img: MdImage,
        }}
      >
        {markdown}
      </ReactMarkdown>
    );
  }, [markdown, CodeBlock, MdImage]);

  useEffect(() => {
    // console.log("render");
    fetch(`/articles/${id}/index.md`)
      .then((m) => {
        // console.log(m);
        return m.text();
      })
      .then((md) => {
        // console.log(md);
        setMarkdown(md);
      });
  }, []);
  return (
    <Box>
      <Grid
        item
        xs={4}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 0,
          color: "white",
          padding: windowWidth >= 600 ? "50px" : "10px",
          marginBottom: 50,
        }}
      >
        <Card
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            // width: windowWidth / 4,
            maxWidth: 1000,
            // height: windowWidth / 4,
          }}
        >
          <Box
            style={{
              color: "black",
              overflowWrap: "anywhere",
              wordBreak: "break-word",
              marginLeft: windowWidth >= 600 ? 100 : 10,
              marginRight: windowWidth >= 600 ? 100 : 10,
              justifyContent: "left",
              alignItems: "start",
              fontSize: 16,
            }}
          >
            {memoForMd}
          </Box>
        </Card>
      </Grid>
    </Box>
  );
};

export default EachPage;
