import { FC } from "react";
import EachPage from "./EachPage";

const Aboutme: FC = () => {
  return (
    <>
      <EachPage id={"Aboutme"} />
    </>
  );
};

export default Aboutme;
