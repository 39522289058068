import { FC, ReactNode, useRef, useState } from "react";
import Sidebar from "./Sidebar";
// import { AppBar, Toolbar } from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DehazeIcon from "@mui/icons-material/Dehaze";
import MenuIcon from "@mui/icons-material/Menu";
import Footer from "./Footer";

type frameprops = { children: ReactNode };

const Frame: FC<frameprops> = (props) => {
  const { children } = { ...props };
  const [isOpen, setIsOpen] = useState(false);

  const ChangeIsOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: -2 }}>
            Naoto Kagaya / Naoto Kawasaki
          </Typography>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={isOpen} setIsOpen={ChangeIsOpen} />
      {children}
      <Footer />
    </>
  );
};

export default Frame;
