import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

const hostname = window.location.hostname;
const pathname = window.location.pathname;
if (
  hostname === "my-homepage-ce66f.web.app" ||
  hostname === "my-homepage-ce66f.firebaseapp.com"
  // || hostname === "localhost"
) {
  // console.log("redirect");
  window.location.replace("https://naoto-kagaya.com" + pathname);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// console.log("index");
root.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
