import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tab,
  Tabs,
} from "@mui/material";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { FC, useLayoutEffect, useState } from "react";
import Axios from "axios";

const Wakati: FC = () => {
  const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [windowWidth, windowHeight] = useWindowSize();
  const [wakatiValue, setWakatiValue] = useState([]);
  const [wakatiCharts, setWakatiCharts] = useState([]);

  const wakatiFunc = (text: string) => {
    // console.log(text);
    const LOCAL = "http://localhost:5000";
    const ANYWHERE = "https://naotokagaya.pythonanywhere.com";
    Axios.post(ANYWHERE + "/wakati", {
      post_text: text,
    }).then((res) => {
      //   console.log(res.data);
      setWakatiValue(res.data.detail);
      setWakatiCharts(res.data.result);
    });
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const BasicTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
      <Box style={{ marginLeft: windowWidth * 0.25 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Table" {...a11yProps(0)} style={{ color: "white" }} />
            <Tab label="Chart" {...a11yProps(1)} style={{ color: "white" }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <WakatiTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WakatiChart />
        </TabPanel>
      </Box>
    );
  };

  const EachRow = (props: any) => {
    // console.log(props.row);
    const row = props.row;
    const detail = row[1];
    // console.log(detail);
    const keyList = detail["key_list"];
    // console.log(keyList);
    const chrs = keyList[0];
    const chrAndPs = chrs.split("\t");
    // console.log(chrAndPs);
    const count = detail["count"];
    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {chrAndPs[0]}
        </TableCell>
        <TableCell align="right">{chrAndPs[1]}</TableCell>
        <TableCell align="right">{keyList[1]}</TableCell>
        <TableCell align="right">{keyList[2]}</TableCell>
        <TableCell align="right">{keyList[3]}</TableCell>
        <TableCell align="right">{keyList[4]}</TableCell>
        <TableCell align="right">{keyList[5]}</TableCell>
        <TableCell align="right">{keyList[6]}</TableCell>
        <TableCell align="right">{keyList[7]}</TableCell>
        <TableCell align="right">{keyList[8]}</TableCell>
        <TableCell align="right">{count}</TableCell>
      </TableRow>
    );
  };

  const WakatiTable = () => (
    <Table
      style={{
        backgroundColor: "white",
        width: windowWidth * 0.5,
        marginTop: 50,
        // marginLeft: windowWidth * 0.25,
        marginRight: windowWidth * 0.25,
        marginBottom: 50,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>単語</TableCell>
          <TableCell align="right">品詞</TableCell>
          <TableCell align="right">品詞細分類1</TableCell>
          <TableCell align="right">品詞細分類2</TableCell>
          <TableCell align="right">品詞細分類3</TableCell>
          <TableCell align="right">活用形</TableCell>
          <TableCell align="right">活用型</TableCell>
          <TableCell align="right">原形</TableCell>
          <TableCell align="right">読み</TableCell>
          <TableCell align="right">発音</TableCell>
          <TableCell align="right">出現数</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {wakatiValue?.map((row) => (
          <EachRow row={row} key={row[0]} />
        ))}
      </TableBody>
    </Table>
  );

  const WakatiChart = () => (
    <Box>
      {/* <ResponsiveContainer
      width={windowWidth * 0.5}
      height={wakatiCharts.length * 50}
    > */}
      <BarChart
        style={{
          backgroundColor: "white",
          width: windowWidth * 0.5,
          marginTop: 50,
          //   marginLeft: windowWidth * 0.25,
          marginRight: windowWidth * 0.25,
          marginBottom: 50,
        }}
        width={windowWidth * 0.5}
        height={wakatiCharts.length * 60}
        data={wakatiCharts}
        layout="vertical"
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="count" type="number" allowDecimals={false} />
        <YAxis dataKey="word" type="category" />
        <Bar dataKey="count" fill="#8884d8" />

        {/* <Legend /> */}
        <Tooltip />
      </BarChart>
      {/* </ResponsiveContainer> */}
    </Box>
  );

  return (
    <>
      <Box>
        <TextareaAutosize
          placeholder="分かち書きをしたい日本語を入力して下さい"
          minRows={5}
          style={{
            width: windowWidth * 0.5,
            marginTop: 50,
            marginLeft: windowWidth * 0.25,
            marginRight: windowWidth * 0.25,
            // height: windowHeight * 0.5,
          }}
          onChange={(v) => {
            // console.log(v);
            wakatiFunc(v.target.value);
          }}
        />
        <BasicTabs />

        {/* <WakatiChart /> */}
      </Box>
    </>
  );
};

export default Wakati;
