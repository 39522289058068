import "./App.css";
import Frame from "./Frame";
// import GraphTest from "./components/GraphTest";
import Home from "./components/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import Appdev from "./components/Appdev";
import Moviewatch from "./components/Moviewatch";
import Clock from "./components/Clock";
import Wakati from "./components/Wakati";
import Aboutme from "./components/Aboutme";
import EachPage from "./components/EachPage";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
import { Location, useLocation } from "react-router-dom";

const useLocationChange = (callback: (location: Location) => void) => {
  const refCallback = useRef<undefined | ((location: Location) => void)>();
  const location = useLocation();

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (refCallback.current) {
      refCallback.current(location);
    }
  }, [location]);
};

const App = () => {
  const sitename = "naoto-kagaya.com";
  let pathname = window.location.pathname;
  let titleText = sitename + pathname;
  // let descriptionText = sitename;
  useLocationChange((location) => {
    // console.log(location.pathname);
    pathname = window.location.pathname;
    titleText = sitename + pathname;
  });

  // useEffect(() => {

  // }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Helmet
          title={titleText}
          link={[{ rel: "canonical", href: "https://" + titleText }]}
          // meta={[{ name: "description", content: descriptionText }]}
        />
        <Frame>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/home" element={<Home />} /> */}
            {/* <Route path="/appdev" element={<Appdev />} /> */}
            <Route path="/articles/:id" element={<EachPage />} />
            {/* <Route path="/movie" element={<Moviewatch />} /> */}
            <Route path="/clock" element={<Clock />} />
            <Route path="/wakati" element={<Wakati />} />
            <Route path="/aboutme" element={<Aboutme />} />
            {/* <Route path="*" element={<NotFoundPage />} /> */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Frame>
      </div>
    </HelmetProvider>
  );
};

export default App;
