/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { FC, useState, useEffect, useLayoutEffect } from "react";
// import dayjs from "dayjs";

const Clock: FC = () => {
  const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [windowWidth, windowHeight] = useWindowSize();

  const useTime = (interval: number) => {
    const [time, updateTime] = useState(Date.now());

    useEffect(() => {
      const timeoutId = setTimeout(() => updateTime(Date.now()), interval);
      return () => {
        clearTimeout(timeoutId);
      };
    }, [time]);

    return time;
  };
  const time = useTime(500);

  const formatDate = (dt: Date) => {
    return new Date(dt).toLocaleString("ja-JP");
  };

  const DigitalClock: FC<any> = ({ time }) => {
    return (
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        {formatDate(time)}
      </div>
    );
  };

  const buildHand = () => {
    const timeFormatArray = [
      {
        format: "h",
        separate: 12,
      },
      {
        format: "m",
        separate: 60,
      },
      {
        format: "s",
        separate: 60,
      },
    ];

    return timeFormatArray.map((value) => {
      return {
        format: value.format,
        separate: 360 / value.separate,
      };
    });
  };

  const Hand: React.FC<any> = ({ time, format, separate }) => {
    const now = new Date(time);
    // console.log(now);
    // const Angle = now * separate;

    let Angle: number;
    const dotTop = 100;
    const dotHeight = (windowHeight / 10) * 5 - dotTop;
    if (format === "h") {
      Angle = now.getHours() * separate;
      //   console.log(Angle);
    }
    if (format === "m") {
      Angle = now.getMinutes() * separate;
      //   console.log(Angle);
    }
    if (format === "s") {
      Angle = now.getSeconds() * separate;
      //   console.log(Angle);
    }

    const SSecHand = () => (
      <Box
        style={{
          width: format === "s" ? "2px" : "4px",
          backgroundColor: "white",
          borderRadius: "100em",
          color: "white",
          position: "absolute",
          left: "50%",
          transformOrigin: "bottom center",
          transform: `translateX(-50%) rotate(${Angle}deg)`,
          height:
            format === "s"
              ? `${dotHeight}px`
              : format === "m"
              ? `${dotHeight}px`
              : format === "h"
              ? `${dotHeight - 50}px`
              : undefined,
          top:
            format === "s"
              ? `${dotTop}px`
              : format === "m"
              ? `${dotTop}px`
              : format === "h"
              ? `${dotTop + 50}px`
              : undefined,
        }}
      >
        {/* {format} */}
      </Box>
    );
    return <SSecHand />;
  };

  const AnalogClock: React.FC<any> = ({ time }) => {
    const [hand, setHand] = useState<any[]>([]);
    useEffect(() => {
      setHand(buildHand());
    }, []);

    return (
      <div>
        {hand.map((value, index) => (
          <Hand
            key={index}
            time={time}
            format={value.format}
            separate={value.separate}
          />
        ))}
      </div>
    );
  };

  return (
    <Box style={{ alignItems: "center", display: "flex", marginTop: 30 }}>
      <DigitalClock time={time} />
      <AnalogClock time={time} />
    </Box>
  );
};

export default Clock;
