import React, { FC, useLayoutEffect, useState } from "react";
// import { slide as Menu } from "react-burger-menu";
import { BrowserRouter, Route, NavLink, Routes, Link } from "react-router-dom";
import style from "./Sidebar.module.css";

import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import DehazeIcon from "@mui/icons-material/Dehaze";
import HomeIcon from "@mui/icons-material/Home";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AccessTimeIconFilled from "@mui/icons-material/AccessTimeFilled";
import MovieIcon from "@mui/icons-material/Movie";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import clsx from "clsx";
import { Card } from "@mui/material";

type propsType = {
  isOpen: boolean;
  setIsOpen: any;
};

const Sidebar: any = (props: propsType) => {
  const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [windowWidth, windowHeight] = useWindowSize();
  const isOpen = props.isOpen;
  const ChangeIsOpen = props.setIsOpen;
  //   console.log(isOpen);

  const SidebarisOpen = (isOpen: boolean) => {
    if (isOpen) {
      return "";
    } else {
      return style.wrapIsNotOpen;
    }
  };
  return (
    <>
      {isOpen && (
        <div
          // className={style.upperdiv}
          style={{
            display: "flex",
            flexDirection: "row",
            position: "fixed",
            flexGrow: 1,
            width: windowWidth,
            height: windowHeight,
            zIndex: 100,
          }}
        >
          <MenuList
            className={clsx(style.menuIsOpen, !isOpen && style.menuIsNotOpen)}
          >
            <Card style={{ backgroundColor: "inherit" }}>
              <NavLink
                to="/"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  ChangeIsOpen();
                }}
              >
                <Card style={{ backgroundColor: "inherit" }}>
                  <MenuItem className={style.menuitem}>
                    <ListItemIcon
                      sx={{ paddingLeft: "0px" }}
                      className={style.itemicon}
                    >
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>Home</ListItemText>
                  </MenuItem>
                </Card>
              </NavLink>
              {/* <NavLink
                to="/appdev"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  ChangeIsOpen();
                }}
              >
                <Card style={{ backgroundColor: "inherit" }}>
                  <MenuItem className={style.menuitem}>
                    <ListItemIcon
                      sx={{ paddingLeft: "0px" }}
                      className={style.itemicon}
                    >
                      <PhoneAndroidIcon />
                    </ListItemIcon>
                    <ListItemText>アプリ開発</ListItemText>
                  </MenuItem>
                </Card>
              </NavLink> */}
              {/* <NavLink
                to="/movie"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  ChangeIsOpen();
                }}
              >
                <Card style={{ backgroundColor: "inherit" }}>
                  <MenuItem className={style.menuitem}>
                    <ListItemIcon
                      sx={{ paddingLeft: "0px" }}
                      className={style.itemicon}
                    >
                      <MovieIcon />
                    </ListItemIcon>
                    <ListItemText>Movie</ListItemText>
                  </MenuItem>
                </Card>
              </NavLink> */}
              <NavLink
                to="/clock"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  ChangeIsOpen();
                }}
              >
                <Card style={{ backgroundColor: "inherit" }}>
                  <MenuItem className={style.menuitem}>
                    <ListItemIcon
                      sx={{ paddingLeft: "0px" }}
                      className={style.itemicon}
                    >
                      <AccessTimeIconFilled />
                    </ListItemIcon>
                    <ListItemText>Clock</ListItemText>
                  </MenuItem>
                </Card>
              </NavLink>
              <NavLink
                to="/wakati"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  ChangeIsOpen();
                }}
              >
                <Card style={{ backgroundColor: "inherit" }}>
                  <MenuItem className={style.menuitem}>
                    <ListItemIcon
                      sx={{ paddingLeft: "0px" }}
                      className={style.itemicon}
                    >
                      <KeyboardIcon />
                    </ListItemIcon>
                    <ListItemText>Wakati</ListItemText>
                  </MenuItem>
                </Card>
              </NavLink>
              <NavLink
                to="/aboutme"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  ChangeIsOpen();
                }}
              >
                <Card style={{ backgroundColor: "inherit" }}>
                  <MenuItem className={style.menuitem}>
                    <ListItemIcon
                      sx={{ paddingLeft: "0px" }}
                      className={style.itemicon}
                    >
                      <ContactPageIcon />
                    </ListItemIcon>
                    <ListItemText>About me</ListItemText>
                  </MenuItem>
                </Card>
              </NavLink>
            </Card>
          </MenuList>
          <div
            className={style.shadowMenuIsOpen}
            style={{
              backgroundColor: "black",
              opacity: 0.4,
              flexGrow: 1,
              marginRight: "auto",
              marginLeft: "auto",
            }}
            onClick={() => ChangeIsOpen()}
          />
        </div>
      )}
    </>
  );
};

export default Sidebar;
