const contentsList = [
  "2022-12-15-webpack5",
  "2022-12-18-react-native-sqlite-storage",
  "2023-01-05-terms-of-service",
  "2023-01-09-privacy-policy",
  "2023-01-16-react-native-appdev",
  "2023-01-24-react-markdown",
  "2023-02-07-dizzyrat-privacy-policy",
  "2023-02-07-dizzyrat-terms-of-service",
  "2023-02-15-movie-inception-chatgpt",
  // "2023-02-19-movie-suzumenotojimari",
  "2023-02-21-react-native-sqlite-storage-chatgpt",
  "2023-04-07-pythonanywhere-mecab",
  "2023-07-30-react-native-sqlite-storage-could-not-open-database",
];

export default contentsList;
