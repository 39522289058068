// import { Box, Grid, Input, Typography } from "@material-ui/core";
import { Box, Grid, Typography, Input, Tabs, Tab, Card } from "@mui/material";
import { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import styles from "./styles/Home.module.css";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock";
import MdImage from "./MdImage";

type EachHomePageProps = { contentsList: string[] };
const EachHomePage: FC<EachHomePageProps> = (props) => {
  const { contentsList } = { ...props };
  // console.log("EachHomePage");
  // console.log(contentsList);
  // console.log(contentsList.sort().reverse());
  const navigate = useNavigate();
  const useWindowSize = (): number[] => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };
  const [windowWidth, windowHeight] = useWindowSize();

  type EachGridType = { id: string };
  const EachGrid: FC<EachGridType> = (props) => {
    const { id } = { ...props };
    // console.log("EachGrid");

    const [markdown, setMarkdown] = useState("");

    const memoForMd = useMemo(() => {
      return (
        <ReactMarkdown
          components={{
            code: CodeBlock,
            img: MdImage,
          }}
        >
          {markdown}
        </ReactMarkdown>
      );
    }, [markdown]);

    useLayoutEffect(() => {
      fetch(`/articles/${id}/index.md`)
        .then((m) => {
          // console.log(m);
          return m.text();
        })
        .then((md) => {
          // console.log(md);
          setMarkdown(md);
        });
    }, []);

    return (
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        style={{
          // marginLeft: "auto",
          // marginRight: "auto",
          marginTop: "auto",
          marginBottom: "auto",
          color: "white",
          paddingTop: windowWidth >= 600 ? 50 : 32,
          paddingLeft: windowWidth >= 600 ? 50 : undefined,
        }}
      >
        <Card
          className={styles.card}
          style={{
            marginLeft: windowWidth >= 600 ? undefined : "auto",
            marginRight: windowWidth >= 600 ? undefined : "auto",
            width:
              windowWidth >= 600 ? windowWidth / 4 : (windowWidth / 10) * 9,
            height: windowWidth >= 600 ? windowWidth / 4 : windowHeight / 4,
          }}
          onClick={() => {
            // console.log("clked");
            // console.log(`/articles/${id}`);
            // navigate(modules.url);
            navigate(`/articles/${id}`);
          }}
        >
          <Box
            style={{
              color: "black",
              overflowWrap: "anywhere",
              wordBreak: "break-word",
              marginLeft: windowWidth >= 1000 ? 30 : 6,
              justifyContent: "left",
              alignItems: "start",

              fontSize: windowWidth >= 1000 ? 16 : 10,
            }}
          >
            {memoForMd}
          </Box>
        </Card>
      </Grid>
    );
  };

  const memoForAllGrid = useMemo(() => {
    return contentsList
      .sort()
      .reverse()
      .map((id) => {
        return <EachGrid id={id} key={id} />;
      });
  }, [EachGrid]);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        sx={{ marginBottom: "60px" }}
      >
        {memoForAllGrid}
      </Grid>
    </Box>
  );
};

export default EachHomePage;
